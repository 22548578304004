var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"h-full flex flex-col",attrs:{"body-style":{flex: 1}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('a-icon',{staticClass:"text-warning hover:opacity-75",attrs:{"type":"edit"},on:{"click":_vm.setLimitToEdit}}),_c('a-icon',{staticClass:"text-danger hover:opacity-75",attrs:{"type":"delete"},on:{"click":_vm.deleteLimit}})]},proxy:true}])},[_c('div',{staticClass:"flex gap-2 items-center"},[_c('a-icon',{attrs:{"type":"bars"}}),_c('div',{staticClass:"truncate"},[_c('div',{staticClass:"overflow-auto py-2 font-semibold"},[_vm._v(" "+_vm._s(_vm.rule.value.commands.join(', '))+" ")])])],1),(!_vm.isLimitNonePermission(_vm.rule) && _vm.rule.value.users.type === _vm.BlackWhiteListItemType.Blacklist)?_c('div',{staticClass:"flex items-center gap-2 mt-2"},[_c('a-icon',{attrs:{"type":"user"}}),_vm._v(" "+_vm._s(_vm.$t('field_command_limits_permissions_title'))+": "+_vm._s(_vm.getVSelectOptionByValue(_vm.permissionsOptions, _vm.rule.value.permissions).label)+" ")],1):_vm._e(),(_vm.isLimitAllPermission(_vm.rule))?[_c('div',{staticClass:"flex items-center gap-2 mt-2"},[_c('a-icon',{attrs:{"type":"info-circle"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('command_limits_count_in_limit_title', [_vm.rule.value.count, _vm.rule.value.interval / this.currentUnit, _vm.unitLabel]))}})],1),_c('black-white-list-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.rule.value,
          'key': 'users',
          'prefix': 'command_limits_preview_',
          'disabled': true,
          'checkedTitle': _vm.$t('command_limits_permissions_white_list'),
          'uncheckedTitle': _vm.$t('command_limits_permissions_black_list'),
          'settingWrapper': {
            'hasFieldButton': true,
            'disabledValue': {
              type: 'Blacklist',
              items: [ ]
            },
            'customiseValue': {
              type: 'Blacklist',
              items: [ ]
            },
          },
        }
      }}})]:_c('div',{staticClass:"text-primary mt-2 font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('commands_not_allowed'))+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }